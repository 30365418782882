<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.return_key") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card grid p-4">
      <custom-form
        :formElements="formElements"
        formStyle="inline"
        @submitForm="returnKey"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import CustomForm from "../components/form/CustomForm.vue";
import { getISODate } from "../utils/dateUtils";
export default {
  components: { Breadcrumb, CustomForm },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const company = computed(() => store.state.company.company);
    const key = computed(() => store.state.key.key);

    const waiting = computed(() => {
      if (store.state.key.waiting) return true;
      return false;
    });

    const formElements = ref([
      {
        class: "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
        element: "datepicker",
        id: "return-date",
        label: "return_date",
        rules: ["isRequired"],
        value: getISODate(new Date()),
        variable: "dateReturned",
      },
      {
        class: "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
        element: "input",
        id: "receiver",
        label: "receiver_name",
        rules: ["isRequired"],
        type: "text",
        value: "",
        variable: "receiver",
      },
      {
        class: "col-span-12 text-right",
        disabled: waiting.value,
        element: "button",
        id: "submit-button",
        label: "key_return",
        textVariant: company.value.primaryText,
        type: "submit",
        variant: company.value.primary,
      },
    ]);

    const returnKey = (formData) => {
      store.dispatch("key/returnKey", {
        id: key.value.id,
        dateReturned: formData.dateReturned,
        receiver: formData.receiver,
      });
    };

    onMounted(() => {
      if (!key.value.id || key.value.id != route.params.keyId) {
        store.dispatch("key/getKey", route.params.keyId);
      }
    });

    return {
      formElements,
      key,
      returnKey,
      route,
      t,
      waiting,
    };
  },
};
</script>
